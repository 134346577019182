const withdrawalRoutes = [
    {
        path: '/withdrawal/info/:studentId',
        name: 'withdrawal.info',
        component: () => import('./components/withdrawal/WithdrawalInfo.vue.vue'),
        type: 'withdrawal',
        props: true,
    },
    {
        path: '/withdrawal/request',
        name: 'withdrawal.request',
        component: () => import('./components/withdrawal/WithdrawalRequest.vue'),
        type: 'withdrawal',
        props: true,
    },
    {
        path: '/withdrawal/review',
        name: 'withdrawal.review',
        component: () => import('./components/withdrawal/WithdrawalReview.vue'),
        type: 'withdrawal',
        props: true,
    },
    {
        path: '/withdrawal/success',
        name: 'withdrawal.success',
        component: () => import('./components/withdrawal/WithdrawalSuccess.vue'),
        type: 'withdrawal',
        props: true,
    },
    {
        path: '/withdrawal/failure',
        name: 'withdrawal.failure',
        component: () => import('./components/withdrawal/WithdrawalFailure.vue'),
        type: 'withdrawal',
        props: true,
    },
    {
        path: '/withdrawal/cancel',
        name: 'withdrawal.cancel',
        component: () => import('./components/withdrawal/WithdrawalCancel.vue'),
        type: 'withdrawal',
        props: true,
    }
];

export default withdrawalRoutes;
