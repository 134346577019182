import Vue from 'vue'
import VueRouter from 'vue-router'
import withdrawalRoutes from './withdrawals-routes';

Vue.use(VueRouter);

const routes = [
    {
        path: '/eligible',
        name: 'scholarship-application.eligible.first',
        component: () => import('./components/scholarship-application/ScholarshipEligiblePage.vue'),
        title: 'Eligible',
        type: 'lls',

        children: [
            {
                path: '/eligibility',
                component: () => import('./components/scholarship-application/ScholarshipInfoScreen.vue'),
                name: 'scholarship-application.eligible.intro-screen',
                title: 'Bursary Info'
            },
            {
                path: '/process',
                name: 'scholarship-application.eligible.process',
                component: () => import('./components/scholarship-application/ScholarshipProcessScreen.vue'),
                title: 'Bursary Application Process'
            },
            {
                path: '/learner-details',
                component: () => import('./components/scholarship-application/applicant/LearnerDetails.vue'),
                name: 'scholarship-application.eligible.learner-details',
                title: 'Learner Details'
            },
        ]
    },
    {
        path: '/applicant',
        name: 'scholarship-application.applicant',
        component: () => import('./components/scholarship-application/applicant/ApplicantSection.vue'),
        title: 'Applicant',
        type: 'lls',
        children: [
            {
                path: '/guardian-info',
                component: () => import('./components/scholarship-application/applicant/GuardianInfo.vue'),
                name: 'scholarship-application.applicant.guardian-info',
                title: 'Guardian Info'
            },
            {
                path: '/financial-info',
                component: () => import('./components/scholarship-application/FinancialInfoPage.vue'),
                name: 'scholarship-application.applicant.financial-info',
                title: 'Financial Info'
            },
            {
                path: '/learning-environment',
                component: () => import('./components/scholarship-application/ScholarshipLearningEnvironment.vue'),
                name: 'scholarship-application.applicant.learning-environment',
                title: 'Learning Environment'
            },
            {
                path: '/assessment',
                component: () => import('./components/scholarship-application/applicant/Assessment.vue'),
                name: 'scholarship-application.applicant.assessment',
                title: 'Bursary Assessment'
            },
            {
                path: '/upload-documents',
                component: () => import('./components/scholarship-application/applicant/DocumentUpload.vue'),
                name: 'scholarship-application.applicant.upload-documents',
                title: 'Upload Documents'
            },
            {
                path: '/final-steps',
                component: () => import('./components/scholarship-application/applicant/FinalSteps.vue'),
                name: 'scholarship-application.applicant.final-steps',
                title: 'Final Steps'
            },
            {
                path: '/we-review',
                component: () => import('./components/scholarship-application/applicant/WeReview.vue'),
                name: 'scholarship-application.applicant.we-review',
                title: 'We Review'
            },
        ]
    },
    {
        path: '/candidate',
        component: () => import('./components/scholarship-application/candidate/CandidateSection.vue'),
        name: 'scholarship-application.candidate',
        title: 'Candidate',
        type: 'lls',
        children: [
            {
                path: '/awaiting-assignment',
                component: () => import('./components/scholarship-application/candidate/AwaitingAssignment.vue'),
                name: 'scholarship-application.candidate.awaiting-assignment',
                title: 'Bursary Candidate'
            },
            {
                path: '/smoa-signing',
                component: () => import('./components/scholarship-application/candidate/SmoaSigning.vue'),
                name: 'scholarship-application.candidate.smoa-signing',
                title: 'Memorandum of Agreement'
            },
            {
                path: '/final-report-upload',
                component: () => import('./components/scholarship-application/candidate/FinalReportUpload.vue'),
                name: 'scholarship-application.candidate.final-report-upload',
                title: 'Final Grade Report'
            },
            {
                path: '/final-review',
                component: () => import('./components/scholarship-application/candidate/FinalReview.vue'),
                name: 'scholarship-application.candidate.final-review',
                title: 'Final Review'
            },
        ]
    },
    {
        path: '/recipient',
        component: () => import('./components/scholarship-application/Recipient.vue'),
        name: 'scholarship-application.recipient',
        title: 'Recipient',
        type: 'lls',
        children: []
    },
    {
        path: '/ineligible',
        component: () => import('./components/scholarship-application/IneligiblePage.vue'),
        name: 'scholarship-application.ineligible',
        title: 'Ineligible',
        type: 'lls',
        children: []
    },
    {
        path: '/applications-closed',
        component: () => import('./components/scholarship-application/ApplicationsClosed.vue'),
        name: 'scholarship-application.applications-closed',
        title: 'Applications Closed',
        type: 'lls',
        children: []
    },

    // start assessment resubmission routes

    {
        path: '/assessment-resubmission/eligibility/:cmId',
        name: 'assessment-resubmission.eligibility',
        component: () => import('./components/assessment-resubmission/AssessmentEligibility.vue'),
        type: 'assessment-resubmission',
    },

    {
        path: '/assessment-resubmission/approved/:cmId',
        name: 'assessment-resubmission.approved',
        component: () => import('./components/assessment-resubmission/ResubmissionApproved.vue'),
        type: 'assessment-resubmission',
    },

    // start withdrawal routes
    ...withdrawalRoutes,
];

function getLlsRoutesWithoutComponent() {
    return routes.filter(route => route.type == 'lls').map(route => {
        return {
            path: route.path,
            name: route.name,
            title: route.title,
            children: route.children.map(child => {
                return {
                    path: child.path,
                    name: child.name,
                    title: child.title,
                };
            })
        }
    });
}

export const navigationRoutes = getLlsRoutesWithoutComponent();

export const router = new VueRouter({
    mode: 'abstract',
    routes: routes,
});
